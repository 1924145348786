import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css'

import { Layout, Section, Block, Slider } from '../components'
import { media } from '../styles/utils'
import {
    container,
    padding,
    colours,
    type,
    bgIcon,
    bgImage,
    buttonSmall,
    button,
} from '../styles/global'
import { parseACF } from '../utils'

const Terms = (props) => {
    const data = parseACF(useStaticQuery(query), 'allWordpressInfopages')
    const [activeIndex, setActiveIndex] = useState(0)

    console.log('Terms -- DATA ---', data)

    // Intro

    const renderIntroduction = () => {
        return (
            <Intro>
                <Wrap>
                    <Title>Terms & Conditions</Title>
                    {data?.introduction && <Text dangerouslySetInnerHTML={{ __html: data?.introduction }} />}
                </Wrap>
            </Intro>
        )
    }

    // Content 

    const renderContent = () => {
        if(!data?.tab_content) return 

        const renderTabNav = () => {
            if(!data?.tab_content) return

            return data?.tab_content.map((item, i) => {
                return (
                    <NavItem
                        key={i}
                        active={activeIndex == i}
                        onClick={() => setActiveIndex(i)}
                    >
                        {item?.name}
                    </NavItem>
                )
            })
        }

        const renderTabContent = () => {
            if(!data?.tab_content) return
        
            return data?.tab_content.map((item, i) => (
                activeIndex == i && item.blocks.map((block, index) => (
                    <Block
                        layout={block.acf_fc_layout}
                        display={block.display}
                        key={index}
                        {...block}
                    />
                ))
            ));
        }

        return (
            <TabbedContent>
                <Navigation>{renderTabNav()}</Navigation>
                <Content>{renderTabContent()}</Content>
            </TabbedContent>
        )
    }

    return (
        <Layout meta={data && data.seo}>
            <Container>
                {renderIntroduction()}
                {renderContent()}
            </Container>
        </Layout>
    )
}

// Shared Components

const Heading = styled.h1``
const Title = styled.h1``
const Text = styled.div``
const Wrap = styled.div``
const SliderWrapper = styled.div``
const Slide = styled.div``
const ImageItem = styled.div``
const Icon = styled.div``


// Shared

const SubHeading = styled.div``
const Button = styled.div``
const Navigation = styled.div``
const CardList = styled.div``
const Meta = styled.div``

// Layout

const Container = styled.div`
    ${container}
    ${padding}
	flex-direction: column;
    Margin-top: 200px;
`

// Intro

const Intro = styled.div`
    margin-bottom: 20px;
    display: flex;

    ${media.tablet`
		flex-direction: column;	
		margin-bottom: 80px;
	`}

    ${Wrap} {
        flex-basis: 100%;

        ${Title} {
            ${type.heading1};
            padding-bottom: 32px;
        }
        ${Text} {
            ${type.body};
            max-width: 80%;

            ${media.tablet`
				max-width: 100%;	
			`}
        }
    }
`

// Tabbed Content

const TabbedContent = styled.div`
    ${Title} {
        ${type.heading1};
        padding-bottom: 32px;

        ${media.tablet`
			padding-bottom: 20px;
		`}
    }

    ${Navigation} {
        display: flex;

        ${media.tablet`
			flex-wrap: wrap;	
		`}
    }
`

const NavItem = styled.div`
    ${button};
    margin-right: 24px;
    background: ${colours.lightGreen};

    ${media.tablet`
		margin-right: 8px;	
		margin-bottom: 12px;	
	`}

    &:hover {
        &:before {
            display: none;
        }
    }

    ${(props) => {
        if (props.active == true)
            return css`
                background: ${colours.black};
                color: ${colours.white};
                border: 3px solid ${colours.black};

                &:hover {
                    background: ${colours.black};
                }
            `
    }}
`

const Content = styled.div`
    border-top: 3px solid ${colours.black};
    margin-top: 44px;
    padding-top: 32px;
    padding-bottom: 80px;

    display: flex;
    flex-direction: column;
    align-items: end;

    ${media.tablet`
		margin-top: 20px;
		border-top: 2px solid ${colours.black};
        padding-bottom: 40px;
	`}

    .block_heading {
        width: 100%;
    }

    .block_heading div {
        ${type.heading3};
        font-family: 'Souvenir', serif;
    }

    .block_text {
        width: 50%;

        ${media.tablet`
            width: 100%;	
        `}

        ul li {
            ${type.heading4};
            margin-bottom: 8px;

            &:before {
                width: 16px;
                height: 16px;
                top: 8px;
            }
        }
    }

    .block_image div {
        align-items: flex-start;
    }

    ${Meta} {
        display: flex;
        flex-direction: column;
        max-width: 50%;

        ${media.tablet`
			max-width: 100%;	
		`}

        ${Title} {
            ${type.body};
            padding-bottom: 32px;

            ${media.tablet`
				padding-bottom: 20px;
			`}
        }

        ${Text} {
            font-size: 21px;

            ${media.tablet`
				font-size: 18px;	
			`}
        }
    }

    ${CardList} {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 40px;

        ${media.tablet`
			margin-bottom: 0px;
		`}
    }
`

// Data

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "terms-and-conditions" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default Terms
